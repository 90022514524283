html, body {
  height: 100%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
}

#root {
  height: 100%;
}

video {
  object-fit: cover;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.over-video {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
}

.qrcode-container {
  text-align: center;
}

.qrcode-container canvas {
  display: inline;
}

.bar-flip-numbers section {
  justify-content: left !important;
}

.ant-card-head-title {
  font-size: 22px;
  padding: 12px 0;
}